// extracted by mini-css-extract-plugin
export var checkbox = "Benefits-module--checkbox--4299b";
export var core = "Benefits-module--core--095f2";
export var form = "Benefits-module--form--e8ae9";
export var formBotton = "Benefits-module--formBotton--5bef0";
export var formContainer = "Benefits-module--form-container--3137c";
export var formTitle = "Benefits-module--form-title--121eb";
export var label = "Benefits-module--label--51831";
export var paddingNone = "Benefits-module--padding-none--ee0ac";
export var submitButton = "Benefits-module--submitButton--7aea5";
export var wrapper = "Benefits-module--wrapper--23067";