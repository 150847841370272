// extracted by mini-css-extract-plugin
export var checkbox = "BenefitsConsortium-module--checkbox--61d2b";
export var core = "BenefitsConsortium-module--core--cad04";
export var form = "BenefitsConsortium-module--form--2c700";
export var formBotton = "BenefitsConsortium-module--formBotton--73513";
export var formContainer = "BenefitsConsortium-module--form-container--26b9a";
export var formTitle = "BenefitsConsortium-module--form-title--74bb2";
export var label = "BenefitsConsortium-module--label--ba4ac";
export var paddingNone = "BenefitsConsortium-module--padding-none--aed1a";
export var sectionContainer = "BenefitsConsortium-module--sectionContainer--e5ab0";
export var submitButton = "BenefitsConsortium-module--submitButton--1fece";
export var wrapper = "BenefitsConsortium-module--wrapper--b5f3a";