// extracted by mini-css-extract-plugin
export var about = "About-module--about--9cc39";
export var blueDark = "About-module--blue-dark--2f2c0";
export var borderGreen = "About-module--border-green--4e01b";
export var core = "About-module--core--16ad0";
export var customBottom = "About-module--custom-bottom--81d7a";
export var customMargin = "About-module--custom-margin--a07c7";
export var customPadding = "About-module--custom-padding--90a70";
export var desk = "About-module--desk--027c7";
export var flex = "About-module--flex--55c4c";
export var largeFont = "About-module--large-font--db549";
export var mobile = "About-module--mobile--136ca";
export var slideDesk = "About-module--slide-desk--826d2";
export var slideMobile = "About-module--slide-mobile--e1aa4";
export var smallFont = "About-module--small-font--f2b4d";